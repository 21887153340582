<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="entity.name"
      :subtitle="$t('StudyPlans')"
      :root="$t('StudyPlans')"
      :root-location="businessDashLink('education/study_plans')"
      icon="mdi-account"
      fill-height
      :preheader-title="$t('wsu.education.study_plan.editor_title')"
      :navigation-return-route="'education/study_plans'"

      :header-action="items.length === 0 ? openImportDialog : null"
      :header-action-text="$t('Import')"

  >
    <template #default>

      <div class="mt-6" />
      <div v-for="semester in (entity.semesters || 0)" :key="semester">

        <!-- Semester Header-->
        <div class="d-flex align-center justify-space-between pointer">
          <h3 @click="expandObject[semester] = !expandObject[semester] " class="pt-3 pb-6 pr-3">
            <v-icon :color="wsACCENT" >
              mdi-menu-{{  expandObject[semester] ? 'down' : 'right'   }}
            </v-icon>
            {{ semester }} {{ $t('Semester') }}
          </h3>

          <div>
            <h5 class="text-right">{{ $tc('CreditsAmount', semesterTotalCredits(semester) )  }}</h5>
            <h5 v-if="semesterItems[semester].length" class="text-right font-weight-regular">{{ $tc('ComponentsAmount', semesterItems[semester].length )  }}</h5>
          </div>

        </div>

        <!-- Content -->
        <v-expand-transition>
          <div v-if="expandObject[semester]">

            <!-- Study Plans Components table -->
            <ws-data-table v-if="semesterItems[semester].length > 0"
                :items="semesterItems[semester] || []"
                :headers="headers"
                dense
                @table-drag-end="handleReoder($event ,semester)"
                disable-pagination
            >

              <template #item.component_name="{item}">
                <ws-select
                    :style="`min-width: ${!SM ? 400 : 200}px`"
                    @input="editComponent( item )"
                    v-model="item.education_program_component_id"
                    :items="getSemesterEducationComponentsSelect(semester , item)"
                    :placeholder="$t('wsu.structure.discipline.choose')"
                    background-color="transparent"
                    autocomplete
                    new-data-text="AddComponent"
                    width="100%"
                    flat solo
                    :append-icon="null"
                    table-style-primary
                    clearable-on-hover
                >
                  <template #item="{item}">
                    <h5 :style="`color : ${wsACCENT}`">
                      {{ item.text }}  ({{ $tc('CreditsAmount' , checkComponentRemainedCredits(item) ) }})
                    </h5>
                  </template>

                </ws-select>
              </template>

              <template #item.credits="{item}">
                <ws-text-field
                    width="60"
                    @change="editComponent(item)"
                    v-model="item.credits"
                    number
                    number-length="3"
                    hide-number-handle
                    solo
                    flat
                    background-color="transparent"
                    placeholder="0"
                    table-style
                >
                </ws-text-field>
              </template>

              <template #item.hours_total="{item}">
                <h5>{{ item.credits*HOURS_PER_CREDIT }}</h5>
              </template>

              <template #item.hours_classroom="{item}">
                <ws-text-field
                    width="60"
                    @change="editComponent(item)"
                    v-model="item.hours_classroom"
                    number
                    number-length="3"
                    hide-number-handle
                    solo
                    flat
                    background-color="transparent"
                    placeholder="0"
                    table-style
                />
              </template>

              <template #item.hours_self="{item}">
                <ws-text-field
                    width="60"
                    @change="editComponent(item)"
                    :value="(item.credits*HOURS_PER_CREDIT) - item.hours_classroom"
                    number
                    number-length="3"
                    hide-number-handle
                    solo
                    flat
                    background-color="transparent"
                    placeholder="0"
                    table-style
                    :error="((item.credits*HOURS_PER_CREDIT) - item.hours_classroom) < 0"
                />
              </template>

              <template v-for="(hourType , i) in classroomHoursTypesSelect"
                        v-slot:[`item.hours_type_${hourType.value}`]="{ item ,index }"  >

                <div :key="i +index + 'type' ">
                  <ws-text-field
                      width="55"
                      @change="editComponent(item)"
                      v-model="item[`hours_type_${hourType.value}`]"
                      number
                      number-length="3"
                      hide-number-handle
                      solo
                      flat
                      background-color="transparent"
                      placeholder="0"
                      table-style
                      outlined
                      :error="getClassRoomTotalSum(item) > item.hours_classroom"
                  />
                </div>

              </template>

              <template #item.control="{item}">

                <ft-select
                    v-if="['discipline', 'on_choice_discipline'].includes(item.type)"
                    @input="editComponent(item)"
                    v-model="item.control"
                    :items="getItemEvaluationSelect(item.education_program_component_id)"
                >
                  <template #default="{text}">
                    <h5 class="text-center text-no-wrap" :style="!item.control ? `color : #9AB2C4 ` : ''" >
                      {{ text || $t('Evaluation') }}
                      <v-icon :color="!item.control ? '#9AB2C4' : null">mdi-menu-down</v-icon>
                    </h5>
                  </template>
                  <template #noItems>
                    <h5 class="pa-3">{{ $t('NoData') }}</h5>
                  </template>
                </ft-select>
              </template>

              <template #item.action="{item , hover}">
                <v-sheet width="28" color="transparent">
                  <v-fade-transition>
                    <v-btn v-if="hover" @click="deleteComponent(item)" icon :color="wsACCENT">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-fade-transition>
                </v-sheet>
              </template>


            </ws-data-table>
            <!-- Add New Component Button / Empty semester text-->
            <div class="pb-3">
              <div class="d-flex justify-center">
                <v-icon v-if="semesterItems[semester].length === 0" size="80" :color="wsDARKLIGHT">mdi-assistant</v-icon>
              </div>
              <h4 v-if="semesterItems[semester].length === 0"  class="text-center my-6 mb-8">{{ $t('wsu.education.study_plan.empty_semester') }}</h4>
              <div class="d-flex" :class="semesterItems[semester].length === 0 ? 'justify-center' : null">
                <ws-button
                    @click="addComponent(semester)"
                    :outlined="semesterItems[semester].length > 0"
                    label="AddEducationComponent"
                    :height="semesterItems[semester].length === 0 ? 36 : 40"
                    :color="semesterItems[semester].length === 0 ? wsATTENTION : wsACCENT"
                />
              </div>
            </div>

          </div>
        </v-expand-transition>

      </div>
    </template>

    <template #dialog>
      <!--  Import Programs -->
      <ws-file-import-dialog
          v-if="displayImportDialog"
          @success="initPage"
          v-model="displayImportDialog"
          entity="study_plan"
          :body="{study_plan_id : uuid }"
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";
import wsFileImportDialog from "@/components/UI/wsFileImportDialog";

export default {
  name: "studyPlanEditor",
  components : {
    wsFileImportDialog
  },
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      entity : {},
      items : [],
      educationComponentsSelect : [],
      classroomHoursTypesSelect : [],
      expandObject : {},

      displayImportDialog : false
    }
  },
  computed : {
    ...mapState('structure' , [
      'selectedLang'
    ]),
    headers() {
      let items = [
        { text : this.$t('wsu.structure.discipline.name.title') ,
          value : 'component_name'  ,
          sortable : false ,
        },
        { text : this.$t('Credits') ,
          value : 'credits' ,
          width: 50,
          sortable : false ,
          align : 'center',
          borderLeft : true,
          padding : 12 ,
        },
        { text : this.$t('TotalHours') ,
          value : 'hours_total' ,
          padding : 12 ,
          width : 1 ,
          sortable : false ,
          align : 'center',
          borderLeft : true,

        },
        { text : this.$t('ClassroomHours') ,
          value : 'hours_classroom' ,
          padding : 4 ,
          width : 50,
          sortable : false ,
          align : 'center',
          borderLeft : true,
          borderRight : true,
        }
      ]

      this.classroomHoursTypesSelect.forEach(item => {
        items.push({
          text : item.shortname,
          value : 'hours_type_' + item.value,
          padding : 4 ,
          width : 50,
          sortable : false,
          align : 'center',
        })
      })

      items.push(
          { text : this.$t('SelfWork') ,
            value : 'hours_self',
            padding : 4 ,
            width : 50,
            sortable : false ,
            align : 'center' ,
            borderLeft : true
          },
          { text : this.$t('ControlForm'),
            value : 'control'  ,
            padding : 12,
            borderLeft : true
          },
          {
            value : 'action',
            width: 1,
            padding : 12,
          }
      )

      return items
    },
    semesterItems() {
      let object = {}
      for ( let i = 1 ; i <= (this.entity.semesters || 0) ; i++) {
        object[i] = this.getSemesterItems(i)
      }

      return object
    },
  },
  methods : {
    ...mapActions('wsu_education' , [
      'GET_STUDY_PLAN_EDITOR' ,
      'ADD_EDIT_STUDY_PLAN_COMPONENT' ,
      'DELETE_STUDY_PLAN_COMPONENT' ,
    ]),

    openImportDialog() {
      this.displayImportDialog = true
    },

    getItemEvaluationSelect(educationComponentId) {
      let component = this.educationComponentsSelect.find( el => el.value === educationComponentId )
      if ( !component) {
        return []
      }
      let items =  this.EVALUATION_SELECT.filter(el => component.control.includes(el.value) )
      items.unshift({
        text : this.$t('None'),
        value : null
      })
      return items
    },

    semesterTotalCredits(semester) {
      let value = 0
      this.semesterItems[semester].forEach(item => {
        if ( item.credits ) {
          value += parseFloat(item.credits)
        }
      })
      return value
    },

    getSemesterEducationComponentsSelect(semester , item) {

      let items = this.educationComponentsSelect
      if ( !this.semesterItems[semester] ) {
        return []
      }
      return items.filter(  el=>
          (!this.semesterItems[semester].map(el => el.education_program_component_id).includes(el.value)
          &&  this.checkComponentRemainedCredits(el) > 0)
          || el.value === item.education_program_component_id
      )
    },

    checkComponentRemainedCredits(item) {

      let value = 0
      let componentsInUse = this.items.filter(el => el.education_program_component_id === item.value)

      componentsInUse.forEach(el => {
        value += Number.parseFloat(el.credits) || 0
      })

      let remainingCredits = (item.credits - value) ?? 0

      if (remainingCredits % 1 === 0) {
        return parseFloat(remainingCredits.toFixed(0)) ;
      } else {
        return parseFloat(remainingCredits.toFixed(1)) ;
      }

    },
    async addComponent(semester) {
      let data = {
        lang : this.selectedLang,
        study_plan_id : this.uuid,
        semester : semester
      }
      let result = await this.ADD_EDIT_STUDY_PLAN_COMPONENT(data)
      if ( !result ) {
        return
      }

      this.items.push(result)

    },
    async editComponent( item ) {

      let component = this.educationComponentsSelect.find(el => el.value === item.education_program_component_id)


      if ( item.education_program_component_id && item.education_program_component_id.includes('new-data::')) {
        return
      }

      let data = this.COPY(item)
      data.lang = this.selectedLang

      if ( item.credits*this.HOURS_PER_CREDIT < item.hours_classroom ) {
        data.hours_classroom = item.credits*this.HOURS_PER_CREDIT
      }

      if ( component ) {
        let index = this.items.findIndex(el => el.uuid === item.uuid )

        this.items[index].credits = 0
        this.items = this.COPY(this.items)

        console.log('data.credits' ,  data.credits , this.checkComponentRemainedCredits(component) , !data.credits || parseFloat(data.credits) > this.checkComponentRemainedCredits(component) )
        if ( !data.credits || data.credits > this.checkComponentRemainedCredits(component) ) {
          data.credits = this.checkComponentRemainedCredits(component)
        }
        let componentControlSelect = this.getItemEvaluationSelect(component.value)
        if ( !data.control && componentControlSelect.length > 0 ) {
          data.control = componentControlSelect[0].value
        }


      }

      let result = await this.ADD_EDIT_STUDY_PLAN_COMPONENT(data)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el => el.uuid === item.uuid )

      if ( index === -1 ) {
        return
      }

      this.items[index]  = result
      this.$set(this.items , index , result )
      this.items = this.COPY(this.items)

    },
    async deleteComponent(item) {
      let result = await this.DELETE_STUDY_PLAN_COMPONENT(item.uuid)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === item.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1)
    },

    handleNewComponent() {
     // this.notify('handleNewComponent')
    },
    handleReoder($event) {
      this.notify($event)
    },
    getSemesterItems(semester) {
      return this.items.filter( el => el.semester === semester)
    },

    //technical
    getClassRoomTotalSum(item) {
      let sum = 0;
      this.classroomHoursTypesSelect.forEach(el => {
        sum += Number.parseFloat(item['hours_type_' + el.value])
      })
      return sum
    },

    async initPage() {
      let result = await this.GET_STUDY_PLAN_EDITOR(this.uuid)
      if ( !result ) {
        return
      }
      this.entity = result.study_plan || {}
      this.items = this.entity.components

      this.educationComponentsSelect = result.education_components_select || []
      this.classroomHoursTypesSelect = result.classroom_hours_types || []

      this.departmentsSelect = result.departments_select
      this.degreesSelect = result.degrees_select
      this.specialtyDegreesSelect = result.university_specialty_degrees_select


      for (let i = 1 ; i <= (this.entity.semesters || 0); i++ ) {
        this.expandObject[i] = i === 1
      }
      this.expandObject = this.COPY(this.expandObject)

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>