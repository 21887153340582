var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.entity.name,"subtitle":_vm.$t('StudyPlans'),"root":_vm.$t('StudyPlans'),"root-location":_vm.businessDashLink('education/study_plans'),"icon":"mdi-account","fill-height":"","preheader-title":_vm.$t('wsu.education.study_plan.editor_title'),"navigation-return-route":'education/study_plans',"header-action":_vm.items.length === 0 ? _vm.openImportDialog : null,"header-action-text":_vm.$t('Import')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"mt-6"}),_vm._l(((_vm.entity.semesters || 0)),function(semester){return _c('div',{key:semester},[_c('div',{staticClass:"d-flex align-center justify-space-between pointer"},[_c('h3',{staticClass:"pt-3 pb-6 pr-3",on:{"click":function($event){_vm.expandObject[semester] = !_vm.expandObject[semester]}}},[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v(" mdi-menu-"+_vm._s(_vm.expandObject[semester] ? 'down' : 'right')+" ")]),_vm._v(" "+_vm._s(semester)+" "+_vm._s(_vm.$t('Semester'))+" ")],1),_c('div',[_c('h5',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$tc('CreditsAmount', _vm.semesterTotalCredits(semester) )))]),(_vm.semesterItems[semester].length)?_c('h5',{staticClass:"text-right font-weight-regular"},[_vm._v(_vm._s(_vm.$tc('ComponentsAmount', _vm.semesterItems[semester].length )))]):_vm._e()])]),_c('v-expand-transition',[(_vm.expandObject[semester])?_c('div',[(_vm.semesterItems[semester].length > 0)?_c('ws-data-table',{attrs:{"items":_vm.semesterItems[semester] || [],"headers":_vm.headers,"dense":"","disable-pagination":""},on:{"table-drag-end":function($event){return _vm.handleReoder($event ,semester)}},scopedSlots:_vm._u([{key:"item.component_name",fn:function(ref){
var item = ref.item;
return [_c('ws-select',{style:(("min-width: " + (!_vm.SM ? 400 : 200) + "px")),attrs:{"items":_vm.getSemesterEducationComponentsSelect(semester , item),"placeholder":_vm.$t('wsu.structure.discipline.choose'),"background-color":"transparent","autocomplete":"","new-data-text":"AddComponent","width":"100%","flat":"","solo":"","append-icon":null,"table-style-primary":"","clearable-on-hover":""},on:{"input":function($event){return _vm.editComponent( item )}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('h5',{style:(("color : " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(item.text)+" ("+_vm._s(_vm.$tc('CreditsAmount' , _vm.checkComponentRemainedCredits(item) ))+") ")])]}}],null,true),model:{value:(item.education_program_component_id),callback:function ($$v) {_vm.$set(item, "education_program_component_id", $$v)},expression:"item.education_program_component_id"}})]}},{key:"item.credits",fn:function(ref){
var item = ref.item;
return [_c('ws-text-field',{attrs:{"width":"60","number":"","number-length":"3","hide-number-handle":"","solo":"","flat":"","background-color":"transparent","placeholder":"0","table-style":""},on:{"change":function($event){return _vm.editComponent(item)}},model:{value:(item.credits),callback:function ($$v) {_vm.$set(item, "credits", $$v)},expression:"item.credits"}})]}},{key:"item.hours_total",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(item.credits*_vm.HOURS_PER_CREDIT))])]}},{key:"item.hours_classroom",fn:function(ref){
var item = ref.item;
return [_c('ws-text-field',{attrs:{"width":"60","number":"","number-length":"3","hide-number-handle":"","solo":"","flat":"","background-color":"transparent","placeholder":"0","table-style":""},on:{"change":function($event){return _vm.editComponent(item)}},model:{value:(item.hours_classroom),callback:function ($$v) {_vm.$set(item, "hours_classroom", $$v)},expression:"item.hours_classroom"}})]}},{key:"item.hours_self",fn:function(ref){
var item = ref.item;
return [_c('ws-text-field',{attrs:{"width":"60","value":(item.credits*_vm.HOURS_PER_CREDIT) - item.hours_classroom,"number":"","number-length":"3","hide-number-handle":"","solo":"","flat":"","background-color":"transparent","placeholder":"0","table-style":"","error":((item.credits*_vm.HOURS_PER_CREDIT) - item.hours_classroom) < 0},on:{"change":function($event){return _vm.editComponent(item)}}})]}},_vm._l((_vm.classroomHoursTypesSelect),function(hourType,i){return {key:("item.hours_type_" + (hourType.value)),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{key:i +index + 'type'},[_c('ws-text-field',{attrs:{"width":"55","number":"","number-length":"3","hide-number-handle":"","solo":"","flat":"","background-color":"transparent","placeholder":"0","table-style":"","outlined":"","error":_vm.getClassRoomTotalSum(item) > item.hours_classroom},on:{"change":function($event){return _vm.editComponent(item)}},model:{value:(item[("hours_type_" + (hourType.value))]),callback:function ($$v) {_vm.$set(item, ("hours_type_" + (hourType.value)), $$v)},expression:"item[`hours_type_${hourType.value}`]"}})],1)]}}}),{key:"item.control",fn:function(ref){
var item = ref.item;
return [(['discipline', 'on_choice_discipline'].includes(item.type))?_c('ft-select',{attrs:{"items":_vm.getItemEvaluationSelect(item.education_program_component_id)},on:{"input":function($event){return _vm.editComponent(item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
return [_c('h5',{staticClass:"text-center text-no-wrap",style:(!item.control ? "color : #9AB2C4 " : '')},[_vm._v(" "+_vm._s(text || _vm.$t('Evaluation'))+" "),_c('v-icon',{attrs:{"color":!item.control ? '#9AB2C4' : null}},[_vm._v("mdi-menu-down")])],1)]}},{key:"noItems",fn:function(){return [_c('h5',{staticClass:"pa-3"},[_vm._v(_vm._s(_vm.$t('NoData')))])]},proxy:true}],null,true),model:{value:(item.control),callback:function ($$v) {_vm.$set(item, "control", $$v)},expression:"item.control"}}):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var hover = ref.hover;
return [_c('v-sheet',{attrs:{"width":"28","color":"transparent"}},[_c('v-fade-transition',[(hover)?_c('v-btn',{attrs:{"icon":"","color":_vm.wsACCENT},on:{"click":function($event){return _vm.deleteComponent(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)],1)]}}],null,true)}):_vm._e(),_c('div',{staticClass:"pb-3"},[_c('div',{staticClass:"d-flex justify-center"},[(_vm.semesterItems[semester].length === 0)?_c('v-icon',{attrs:{"size":"80","color":_vm.wsDARKLIGHT}},[_vm._v("mdi-assistant")]):_vm._e()],1),(_vm.semesterItems[semester].length === 0)?_c('h4',{staticClass:"text-center my-6 mb-8"},[_vm._v(_vm._s(_vm.$t('wsu.education.study_plan.empty_semester')))]):_vm._e(),_c('div',{staticClass:"d-flex",class:_vm.semesterItems[semester].length === 0 ? 'justify-center' : null},[_c('ws-button',{attrs:{"outlined":_vm.semesterItems[semester].length > 0,"label":"AddEducationComponent","height":_vm.semesterItems[semester].length === 0 ? 36 : 40,"color":_vm.semesterItems[semester].length === 0 ? _vm.wsATTENTION : _vm.wsACCENT},on:{"click":function($event){return _vm.addComponent(semester)}}})],1)])],1):_vm._e()])],1)})]},proxy:true},{key:"dialog",fn:function(){return [(_vm.displayImportDialog)?_c('ws-file-import-dialog',{attrs:{"entity":"study_plan","body":{study_plan_id : _vm.uuid }},on:{"success":_vm.initPage},model:{value:(_vm.displayImportDialog),callback:function ($$v) {_vm.displayImportDialog=$$v},expression:"displayImportDialog"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}})}
var staticRenderFns = []

export { render, staticRenderFns }